$color_1: rgba(0, 0, 0, 0.8);
$color_2: rgba(0, 0, 0, 0.6);
$color_3: #222;
$color_4: #444;
$color_5: inherit;

:root {
  --bs-font-sans-serif: -apple-system, "Nunito Sans", BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

.bg-light {
  background-color: #f4f6f8;
}

/**
   * Enable smooth scrolling on the whole document
   */
/**
   * Disable smooth scrolling when users have prefers-reduced-motion enabled
   */
/**
   * Add .section to every anchored element for scroll margin
   */
/* Header */
/* Hero (intro) section */
@-webkit-keyframes animateWave {
  0% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes animateWave {
  0% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}
/* Masonary grid for portfolio */
/* Grid Item */
/* Portfolio */
/* Portolio Caption */
/* Footer section */
/* Scroll Top */
/* Additional utility styles */
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}
.section {
  scroll-margin-top: 2rem;
}
.site-title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3rem;
  text-decoration: none;
}
.nav-link {
  font-weight: 700;
  color: $color_1 !important;
  &:hover {
    color: $color_2 !important;
  }
  &:focus {
    color: $color_2 !important;
  }
}
.social-nav {
  .nav-link {
    padding: 0 0.5rem;
    font-size: 1.1rem;
    line-height: 2.5rem;
  }
}
.active {
  .nav-link {
    color: $color_2 !important;
  }
}
.intro-title {
  font-weight: 800;
}
.wave-bg {
  display: block;
  height: 220px;
  width: 100%;
  min-width: 600px;
  transform-origin: top;
  -webkit-animation: animateWave 2000ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
  animation: animateWave 2000ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
  background-image: url("./images/wave-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
}
img.avatar {
  border-radius: 30px;
}
.grid {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}
.grid-sizer {
  width: 100%;
}
.grid-item {
  width: 100%;
  padding-bottom: 12px;
  img {
    display: block;
    width: 100%;
    max-width: 100%;
  }
}
.portolio-section {
  .container {
    padding: 0;
  }
}
.portfolio-item {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin: 0;
  figcaption {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    background: rgba(255, 255, 255, 0.75);
    transition: transform 0.35s;
    transform: translate3d(0, 100%, 0);
    h4 {
      color: $color_3;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 3px;
    }
    p {
      color: $color_4;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  &:hover {
    figcaption {
      transform: translate3d(0, 0, 0);
    }
  }
}
#bp_container {
  .bp-xc {
    background: #f6e05e !important;
  }
}
#bp_caption {
  a {
    text-decoration: none;
  }
}
footer {
  a {
    &:not(.nav-link) {
      color: $color_5;
      font-weight: 600;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
#scrolltop {
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease-in;
  position: fixed;
  bottom: 20px;
  right: 20px;
  .btn {
    padding: 3px 11px;
    border-radius: 50%;
  }
}
.container-narrow {
  max-width: 1024px;
  margin: 0 auto;
}
.text-small {
  font-size: 0.875rem;
}
.text-xsmall {
  font-size: 0.775rem;
  line-height: 0.85rem;
}
.text-uppercase {
  letter-spacing: 0.05em;
}
.hover-effect {
  transition: transform 0.18s ease-in-out;
  &:hover {
    transform: translateY(-2px);
  }
}
.marker {
  position: relative;
  display: inline;
  width: auto;
  &:after {
    content: "";
    width: 125px;
    height: 30px;
    position: absolute;
    bottom: -25px;
    right: -30px;
    background-image: url("./images/marker.svg");
    background-repeat: no-repeat;
  }
}
.marker-center {
  padding-right: 0;
  &:after {
    bottom: -30px;
    left: 50%;
    margin-left: -60px;
  }
}
.entry-title {
  a {
    text-decoration: none;
  }
}
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
@media (min-width: 48em) {
  .site-title {
    float: left;
  }
  .grid-sizer {
    width: 25%;
  }
  .grid-item {
    width: 25%;
    float: left;
    padding: 6px;
  }
}
@media (max-width: 575.98px) {
  .marker {
    padding-right: 0;
    &:after {
      bottom: -30px;
      left: 50%;
      margin-left: -60px;
    }
  }
}
